import React, { Component } from 'react';
import './App.css';
import img_embed from './board-911636_640.jpg'
import img_web from './coding-1853305_640.jpg'
import img_sp from './iphone-3566142_640.jpg'
import img_cloud from './cloud-2104829_640.jpg'
import img_aurora from './aurora-borealis-3877316_640.jpg'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    background: "#fff",
    [theme.breakpoints.up("lg")]: {
      width: 1170
    }
  },
  card: {
    maxWidth: 345,
  },
  media: {
    objectFit: 'cover',
  },
});

function Header(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <AppBar position="static" color="secondary">
        <Toolbar>
          <Typography variant="h6" color="inherit">
            LAND HIGHEST
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}

function MediaCard(props) {
  const { classes } = props;
  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          component='img'
          className={classes.media}
          image={props.img}
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.CardTitle}
          </Typography>
            {props.children}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

class App extends Component {
  render() {
    const {classes} = this.props;
    return (
      <div className="App">
      
        <Grid container justify="center">
          <Grid container spacing={16} className={classes.container}> 
            <Grid item xs={12}>
              <Header classes={PropTypes.object.isRequired}/>
            </Grid>
            <Grid item sm={12}>
              <div style={{marginTop:200, marginBottom:200}}>
                <Typography component="h2" variant="h2" gutterBottom>LAND HIGHEST</Typography>
                <Typography variant="subtitle1" gutterBottom>さまざまな分野のソフトウェア開発を支援。</Typography>
                <Typography variant="subtitle1" gutterBottom>素早いプロトタイピングを実現します。</Typography>
              </div>
            </Grid>
            
            <Grid item xs={12} style={{backgroundColor:'rgba(200,200,200,0.1)'}}>
              <div style={{marginTop:40, marginBottom:40}}>
                <Typography component="h2" variant="display3" gutterBottom>Solutions</Typography>
              </div>
            </Grid>
            
            <Grid item sm={6} style={{backgroundColor:'rgba(200,200,200,0.1)'}}>
              <MediaCard img={img_embed} classes={PropTypes.object.isRequired} CardTitle={'組み込みソフトウェア開発'}>
                <Chip label="C" variant="outlined" style={{margin: 2}}/>
                <Chip label="C++" variant="outlined" style={{margin: 2}}/>
                <Chip label="Mbed" variant="outlined" style={{margin: 2}}/>
                <Chip label="RaspberryPi" variant="outlined" style={{margin: 2}}/>
              </MediaCard>
            </Grid>
            <Grid item sm={6} style={{backgroundColor:'rgba(200,200,200,0.1)'}}>
              <MediaCard img={img_web} classes={PropTypes.object.isRequired} CardTitle={'Web開発'}>
                <Chip label="React" variant="outlined" style={{margin: 2}}/>
                <Chip label="jQuery" variant="outlined" style={{margin: 2}}/>
                <Chip label="JavaScript" variant="outlined" style={{margin: 2}}/>
                <Chip label="ASP.NET MVC" variant="outlined" style={{margin: 2}}/>
                <Chip label="C#" variant="outlined" style={{margin: 2}}/>
                <Chip label="SQL" variant="outlined" style={{margin: 2}}/>
              </MediaCard>
            </Grid>
            <Grid item sm={6} style={{backgroundColor:'rgba(200,200,200,0.1)'}}>
              <MediaCard img={img_sp} classes={PropTypes.object.isRequired} CardTitle={'スマートフォンアプリ開発'}>
                <Chip label="iOS" variant="outlined" style={{margin: 2}}/>
                <Chip label="Android" variant="outlined" style={{margin: 2}}/>
                <Chip label="Swift" variant="outlined" style={{margin: 2}}/>
                <Chip label="React Native" variant="outlined" style={{margin: 2}}/>
                <Chip label="Xamarin" variant="outlined" style={{margin: 2}}/>
              </MediaCard>
            </Grid>
            <Grid item sm={6} style={{backgroundColor:'rgba(200,200,200,0.1)'}}>
              <MediaCard img={img_cloud} classes={PropTypes.object.isRequired} CardTitle={'クラウドソリューション'} >
                <Chip label="Microsoft Azure" variant="outlined" style={{margin: 2}}/>
                <Chip label="GCP" variant="outlined" style={{margin: 2}}/>
                <Chip label="AWS" variant="outlined" style={{margin: 2}}/>
              </MediaCard>
            </Grid>
          </Grid>

          <Grid item sm={12}>
            <div style={{marginTop:50, marginBottom:50, height:370, backgroundImage:`url(${img_aurora})`, backgroundSize:'cover'}}>
              <div style={{backgroundColor:'rgba(0,0,0,0.5)', height:300, paddingTop:70}}>
                <Typography component="h2" variant="display3" gutterBottom style={{color:'#f0f0f0'}}>About</Typography>
                <Typography variant="subtitle1" gutterBottom style={{color:'#f0f0f0'}}>LAND HIGHESTはフリーランスのシステムエンジニアです。</Typography>
                <Typography variant="subtitle1" gutterBottom style={{color:'#f0f0f0'}}>お気軽に下記連絡先までお問い合わせ、ご相談ください。</Typography>
              </div>
            </div>
          </Grid>

          <Grid item sm={12}>
            <div style={{marginTop:200, marginBottom:200}}>
              <Typography component="h2" variant="display3" gutterBottom>Contact</Typography>
              <Typography variant="body1" gutterBottom>info@landhighest.com</Typography>
              <CopyToClipboard text="info@landhighest.com" onCopy={() => {alert('done!')}}>
                <Button variant="outlined" className={classes.button}>Copy To ClipBoard</Button>
              </CopyToClipboard> 
            </div>
          </Grid>

          <Grid item xs={12}>
            <div>
              <Typography variant="caption" align="center" color="textSecondary">
                copyright LAND HIGHEST 2018
              </Typography>
            </div>
          </Grid>
        </Grid>  
      </div>
    );
  }
}

export default withStyles(styles)(App);
